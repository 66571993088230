import { MenuItem } from "react-pro-sidebar";
import { useLocation } from "react-router-dom";

interface Props {
  icon: string;
  link: string;
  onClick: (link: string, isSubMenu?: boolean) => void;
  isSubMenu?: boolean;
  children: React.ReactNode;
}

const NavigationItem = ({
  icon,
  link,
  onClick,
  isSubMenu,
  children,
}: Props) => {
  const location = useLocation();

  const isActive = location.pathname.includes(link);

  const handleOnClick = () => {
    onClick(link, isSubMenu);
  };

  return (
    <MenuItem
      icon={<i className={`fa ${icon}`} style={{ fontSize: "20px" }} />}
      onClick={handleOnClick}
      active={isActive}
    >
      {children}
    </MenuItem>
  );
};

export default NavigationItem;
