import { Status } from "./status";
import { SubscriptionSequence, SubscriptionType } from "./subscription";

export enum PromotionalCodeType {
  Percentage = 0,
  Amount = 1,
}

export interface PromotionalCode {
  id: string;
  code: string;
  amount: number;
  type: PromotionalCodeType;
  usages: number;
  maxUsages?: number | null;
  validUntil?: string | null;
  requiredSubscriptionType?: SubscriptionType | null;
  requiredSubscriptionSequence?: SubscriptionSequence | null;
  used?: string | null;
  status?: Status;
  isRecurrent: boolean;
  createdBy: string;
  createdAt: Date;
}
