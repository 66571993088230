import { Settings } from "luxon";
import { useContext, useEffect } from "react";
import CacheBuster from "react-cache-buster";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Config } from "./Config";
import { ModuleType } from "./Modules";
import { Permissions } from "./Permissions";
import AccountLayout from "./components/layout/AccountLayout";
import Layout from "./components/layout/Layout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RequestPassword from "./pages/RequestPassword";
import Trial from "./pages/Trial";
import { AccountContextProvider } from "./store/account-context";
import AuthContext from "./store/auth-context";
import lazyLoad from "./utils/lazyLoad";
import TrialLayout from "./components/layout/TrialLayout";

/**
 * Public client modules imports.
 */
const ClientContainer = lazyLoad(() => import("../frames/layout/Container"));
const VoucherOrderFrame = lazyLoad(
  () => import("../frames/vouchers/VoucherOrderFrame")
);
const ReservationFrame = lazyLoad(
  () => import("../frames/reservations/create/ReservationFrame")
);
const ReservationUpdate = lazyLoad(
  () => import("../frames/reservations/update/ReservationUpdate")
);
const DigitalMenuPublic = lazyLoad(
  () => import("../frames/digitalmenu/DigitalMenuPublic")
);
const ReviewFrame = lazyLoad(() => import("../frames/reviews/ReviewFrame"));
const ReviewListFrame = lazyLoad(
  () => import("../frames/reviews/ReviewListFrame")
);
const NewsletterSubscribeFrame = lazyLoad(
  () => import("../frames/newsletter/NewsletterSubscribeFrame")
);
const NewsletterUnsubscribeFrame = lazyLoad(
  () => import("../frames/newsletter/NewsletterUnsubscribeFrame")
);

/**
 * Main application imports.
 */
const Dashboard = lazyLoad(() => import("./pages/Dashboard"));
const Account = lazyLoad(() => import("./pages/Account"));
const Clients = lazyLoad(() => import("./pages/modules/clients/Clients"));
const ClientsDetail = lazyLoad(
  () => import("./pages/modules/clients/ClientsDetail")
);
const Vouchers = lazyLoad(() => import("./pages/modules/vouchers/Vouchers"));
const VoucherDetail = lazyLoad(
  () => import("./pages/modules/vouchers/VoucherDetail")
);
const DigitalMenu = lazyLoad(() => import("./pages/modules/DigitalMenu"));
const NewsletterList = lazyLoad(
  () => import("./pages/modules/newsletters/NewsletterList")
);
const NewsletterCreate = lazyLoad(
  () => import("./pages/modules/newsletters/NewsletterCreate")
);
const NewsletterVerify = lazyLoad(
  () => import("./pages/modules/newsletters/NewsletterVerify")
);
const Reservations = lazyLoad(
  () => import("./pages/modules/Reservations/Reservations")
);
const Calls = lazyLoad(() => import("./pages/modules/calls/Calls"));
const CallDetail = lazyLoad(() => import("./pages/modules/calls/CallDetail"));
const ReservationsPrintPdf = lazyLoad(
  () => import("./pages/modules/Reservations/ReservationsPrintPdf")
);
const Reviews = lazyLoad(() => import("./pages/modules/reviews/Reviews"));
const ReviewDetail = lazyLoad(
  () => import("./pages/modules/reviews/ReviewDetail")
);
const BusinessSettingsGeneral = lazyLoad(
  () => import("./pages/settings/business/General")
);
const BusinessSettingsOpeningHours = lazyLoad(
  () => import("./pages/settings/business/OpeningHours")
);
const BusinessSettingsOnlinePayments = lazyLoad(
  () => import("./pages/settings/business/OnlinePayments")
);
const BusinessSettingsModules = lazyLoad(
  () => import("./pages/settings/business/Modules")
);
const BusinessSettingsNotifications = lazyLoad(
  () => import("./pages/settings/business/Notifications")
);
const BusinessSettingsFrames = lazyLoad(
  () => import("./pages/settings/business/Frames")
);
const BusinessSettingsSubscription = lazyLoad(
  () => import("./pages/settings/business/Subscription")
);
const Users = lazyLoad(() => import("./pages/settings/Users"));
const TemplateList = lazyLoad(
  () => import("./pages/settings/templates/TemplateList")
);
const TemplateCreate = lazyLoad(
  () => import("./pages/settings/templates/TemplateCreate")
);
const Floors = lazyLoad(() => import("./pages/settings/Floors"));
const MenuList = lazyLoad(() => import("./pages/settings/menus/MenuList"));
const MenuDetail = lazyLoad(() => import("./pages/settings/menus/MenuDetail"));
const MenuCategoryDetail = lazyLoad(
  () => import("./pages/settings/menus/MenuCategoryDetail")
);
const SpecialCategoryList = lazyLoad(
  () => import("./pages/settings/specials/SpecialCategoryList")
);
const SpecialItemList = lazyLoad(
  () => import("./pages/settings/specials/SpecialItemList")
);
const Integrations = lazyLoad(() => import("./pages/settings/Integrations"));
const Businesses = lazyLoad(() => import("./pages/admin/Businesses"));
const Logs = lazyLoad(() => import("./pages/admin/Logs"));
const AdminStats = lazyLoad(() => import("./pages/admin/AdminStats"));
const PromotionalCodes = lazyLoad(
  () => import("./pages/admin/PromotionalCodes")
);

/**
 * Environement variables.
 */
const BUILD_IDENTIFIER = Config.buildNumber;
const IS_STAGING = Config.mode === "staging";
const IS_DEVELOPMENT = Config.mode === "development";

/**
 * The actual application.
 */
const App = () => {
  const authContext = useContext(AuthContext);
  const { i18n } = useTranslation();

  Settings.defaultLocale = i18n.language;

  useEffect(() => {
    BUILD_IDENTIFIER &&
      console.log("Application build identifier: v" + BUILD_IDENTIFIER);

    !IS_STAGING &&
      !IS_DEVELOPMENT &&
      console.log(
        "%c Aan het rondkijken in RestoManagers code? 👀 ",
        "background: #7367f0; color: white; padding: 20px;"
      );
  }, []);

  const newslettersVerified =
    authContext?.business && authContext.business.newsletterSettings
      ? authContext.business.newsletterSettings.isVerified
      : false;

  return (
    <CacheBuster
      isEnabled={!!BUILD_IDENTIFIER}
      currentVersion={BUILD_IDENTIFIER ?? ""}
    >
      <>
        {IS_STAGING && (
          <div
            className="position-fixed bottom-0 start-0 m-3"
            style={{ zIndex: 10000 }}
          >
            <span className="badge rounded-pill bg-warning text-dark">
              Staging
            </span>
          </div>
        )}

        {IS_DEVELOPMENT && (
          <div
            className="position-fixed bottom-0 start-0 m-3"
            style={{ zIndex: 10000 }}
          >
            <span className="badge rounded-pill bg-danger text-dark">
              Development
            </span>
          </div>
        )}

        <BrowserRouter>
          <Routes>
            <Route path="/clients" element={<ClientContainer />}>
              <Route path="voucher/order" element={<VoucherOrderFrame />} />
              <Route path="reservation">
                <Route index element={<ReservationFrame />} />
                <Route path=":id" element={<ReservationUpdate />} />
              </Route>
              <Route path="digitalmenu" element={<DigitalMenuPublic />} />
              <Route path="reviews">
                <Route index element={<ReviewFrame />} />
                <Route path="list" element={<ReviewListFrame />} />
              </Route>
              <Route path="newsletter">
                <Route
                  path="subscribe"
                  element={<NewsletterSubscribeFrame />}
                />
                <Route
                  path="unsubscribe"
                  element={<NewsletterUnsubscribeFrame />}
                />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>

            {!authContext?.isLoggedIn && (
              <Route>
                <Route path="/" element={<AccountLayout />}>
                  <Route index element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="trial" element={<Trial />} />
                  <Route path="forgotPassword" element={<RequestPassword />} />
                  <Route path="*" element={<Login />} />
                </Route>
                <Route path="trial" element={<TrialLayout />}>
                  <Route index path="*" element={<Trial />} />
                </Route>
              </Route>
            )}

            {authContext?.isLoggedIn && (
              <>
                <Route
                  path="modules/reservations/print"
                  element={<ReservationsPrintPdf />}
                />

                <Route
                  path="/"
                  element={
                    <AccountContextProvider>
                      <Layout />
                    </AccountContextProvider>
                  }
                >
                  <Route index element={<Navigate to="/dashboard" replace />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="account" element={<Account />} />

                  <Route path="modules">
                    {authContext.hasModule(ModuleType.Clients) &&
                      authContext.hasPermission(Permissions.clientList) && (
                        <Route path="clients">
                          <Route index element={<Clients />} />
                          <Route path=":id" element={<ClientsDetail />} />
                        </Route>
                      )}

                    {authContext.hasModule(ModuleType.Vouchers) &&
                      authContext.hasPermission(Permissions.voucherList) && (
                        <Route path="vouchers">
                          <Route index element={<Vouchers />} />
                          <Route path=":id" element={<VoucherDetail />} />
                        </Route>
                      )}

                    {authContext.hasModule(ModuleType.DigitalMenu) &&
                      authContext.hasPermission(
                        Permissions.digitalMenuManage
                      ) && (
                        <Route path="digitalmenu" element={<DigitalMenu />} />
                      )}

                    {authContext.hasModule(ModuleType.Newsletters) &&
                      authContext.hasPermission(Permissions.newsletterList) && (
                        <Route path="newsletters">
                          {!newslettersVerified ? (
                            <Route
                              index
                              path="*"
                              element={<NewsletterVerify />}
                            />
                          ) : (
                            <>
                              <Route index element={<NewsletterList />} />
                              <Route
                                path="create"
                                element={<NewsletterCreate />}
                              />
                              <Route
                                path=":newsletterId"
                                element={<NewsletterCreate />}
                              />
                            </>
                          )}
                        </Route>
                      )}

                    {authContext.hasModule(ModuleType.Reservations) &&
                      authContext.hasPermission(
                        Permissions.reservationList
                      ) && (
                        <Route path="reservations" element={<Reservations />} />
                      )}

                    {authContext.hasModule(ModuleType.Reviews) &&
                      authContext.hasPermission(Permissions.reviewList) && (
                        <Route path="reviews">
                          <Route index element={<Reviews />} />
                          <Route path=":id" element={<ReviewDetail />} />
                        </Route>
                      )}

                    {authContext.hasModule(ModuleType.Calls) &&
                      authContext.hasPermission(Permissions.callsList) && (
                        <Route path="calls">
                          <Route index element={<Calls />} />
                          <Route path=":id" element={<CallDetail />} />
                        </Route>
                      )}
                  </Route>

                  <Route path="settings">
                    {authContext.hasPermission(Permissions.businessManage) && (
                      <Route path="business">
                        <Route
                          path="general"
                          element={<BusinessSettingsGeneral />}
                        />
                        <Route
                          path="openinghours"
                          element={<BusinessSettingsOpeningHours />}
                        />
                        <Route
                          path="payments"
                          element={<BusinessSettingsOnlinePayments />}
                        />
                        <Route
                          path="modules"
                          element={<BusinessSettingsModules />}
                        />
                        <Route
                          path="notifications"
                          element={<BusinessSettingsNotifications />}
                        />
                        <Route
                          path="frames"
                          element={<BusinessSettingsFrames />}
                        />
                        <Route
                          path="subscription"
                          element={<BusinessSettingsSubscription />}
                        />
                        <Route
                          index
                          path="*"
                          element={<Navigate to="./general" replace />}
                        />
                      </Route>
                    )}
                    {authContext.hasPermission(Permissions.floorManage) && (
                      <Route path="floors" element={<Floors />} />
                    )}
                    {authContext.hasPermission(Permissions.templateManage) && (
                      <Route path="templates">
                        <Route index element={<TemplateList />} />
                        <Route path="create" element={<TemplateCreate />} />
                        <Route
                          path=":templateId"
                          element={<TemplateCreate />}
                        />
                      </Route>
                    )}
                    {authContext.hasPermission(Permissions.menuManage) && (
                      <Route path="menus">
                        <Route index element={<MenuList />} />
                        <Route path=":menuId" element={<MenuDetail />} />
                        <Route path=":menuId/categories">
                          <Route
                            path=":categoryId"
                            element={<MenuCategoryDetail />}
                          />
                        </Route>
                      </Route>
                    )}
                    {authContext.hasPermission(
                      Permissions.reservationManage
                    ) && (
                      <Route path="specials">
                        <Route index element={<SpecialCategoryList />} />
                        <Route
                          path=":categoryId"
                          element={<SpecialItemList />}
                        />
                      </Route>
                    )}
                    {authContext.hasPermission(Permissions.userManage) && (
                      <Route path="users" element={<Users />} />
                    )}
                    {authContext.hasPermission(Permissions.businessManage) && (
                      <Route path="integrations" element={<Integrations />} />
                    )}
                  </Route>

                  {authContext.role === "Admin" && (
                    <Route path="admin">
                      <Route path="businesses" element={<Businesses />} />
                      <Route path="logs" element={<Logs />} />
                      <Route path="adminstats" element={<AdminStats />} />
                      <Route
                        path="promotionalcodes"
                        element={<PromotionalCodes />}
                      />
                    </Route>
                  )}

                  <Route
                    path="*"
                    element={<Navigate to="/dashboard" replace />}
                  />
                </Route>
              </>
            )}
          </Routes>
        </BrowserRouter>
      </>
    </CacheBuster>
  );
};

export default App;
